<script lang="ts">
	import { T } from '@tolgee/svelte';
	import Image from '$lib/Image.svelte';
</script>

<section class="bg-white x-wrapper">
	<div class="py-8 lg:py-16">
		<div class="text-center text-gray-900">
			<h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 lg:text-5xl">
				<T keyName="content_headline" />
			</h2>
		</div>
		<div class="grid gap-6 mt-12 lg:mt-14 lg:gap-12 md:grid-cols-3">
			<div class="flex mb-2 flex-col md:mb-0 gap-4">
				<Image
					loading="lazy"
					sizeFactor="0.75"
					class="mr-4 w-5/6 md:h-auto rounded-lg max-w-xs"
					fileName="content-1-Analyse.webp"
					alt="content 1"
				/>
				<div>
					<h3 class="text-xl font-bold mb-2.5 text-gray-900">
						<T keyName="content_part1_title" />
					</h3>
					<p class="text-gray-500">
						<T keyName="content_part1_description" />
					</p>
				</div>
			</div>
			<div class="flex mb-2 flex-col md:mb-0 gap-4">
				<Image
					loading="lazy"
					sizeFactor="0.75"
					class="mr-4 w-5/6 md:h-auto rounded-lg max-w-xs"
					fileName="content-2-Device.webp"
					alt="content 2"
				/>
				<div>
					<h3 class="text-xl font-bold mb-2.5 text-gray-900">
						<T keyName="content_part2_title" />
					</h3>
					<p class="text-gray-500">
						<T keyName="content_part2_description" />
					</p>
				</div>
			</div>
			<div class="flex flex-col gap-4">
				<Image
					loading="lazy"
					sizeFactor="0.75"
					class="mr-4 w-5/6 md:h-auto rounded-lg max-w-xs"
					fileName="content-3-Home.webp"
					alt="content 3"
				/>
				<div>
					<h3 class="text-xl font-bold mb-2.5 text-gray-900">
						<T keyName="content_part3_title" />
					</h3>
					<p class="text-gray-500">
						<T keyName="content_part3_description" />
					</p>
				</div>
			</div>
		</div>
	</div>
</section>
