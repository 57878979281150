<script lang="ts">
	import { getCDNUrlFromFilename } from '$lib/utils';

	export let fileName: string;
	export let fetchPriority = 'auto';
	export let alt = '';
	export let sizeFactor = 1;
	export let loading: 'lazy' | 'eager' | null = null;
	const SIZES = Object.freeze([320, 480, 640, 768, 1024, 1280]);
</script>

<img
	{fetchPriority}
	{loading}
	src={getCDNUrlFromFilename(fileName)}
	srcset={SIZES.map(
		(size) => `${getCDNUrlFromFilename(fileName, size * sizeFactor)} ${size}w`
	).join(',')}
	{alt}
	{...$$restProps}
/>
