<script lang="ts">
	import { getTranslate, T } from '@tolgee/svelte';
	import PrimaryButton from '$lib/buttons/PrimaryButton.svelte';
	import Image from '$lib/Image.svelte';

	const t = getTranslate();
</script>

<section class="bg-white x-wrapper">
	<div class="gap-16 items-center py-8 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16">
		<div class="font-light text-gray-500 sm:text-lg">
			<h2 class="mb-4 text-4xl tracking-tight font-extrabold break-words text-gray-900">
				<T keyName="introduction_headline" />
			</h2>
			<p>
				{@html $t('introduction_content')}
			</p>
			<PrimaryButton on:click={() => window.scrollTo({ top: 0 })} size="l"
				><span class="text-lg">{$t('introduction_signup_cta')}</span></PrimaryButton
			>
		</div>
		<div class="grid grid-cols-2 gap-4 mt-8">
			<Image
				loading="lazy"
				class="w-full rounded-lg"
				fileName="intro-1.webp"
				alt="intro 1"
				sizeFactor="0.5"
				fetchPriority="low"
			/>
			<Image
				loading="lazy"
				class="mt-4 w-full lg:mt-10 rounded-lg"
				fileName="intro-2.webp"
				alt="intro 2"
				sizeFactor="0.5"
				fetchPriority="low"
			/>
		</div>
	</div>
</section>
