<script lang="ts">
	import { getTranslate, T } from '@tolgee/svelte';

	const t = getTranslate();
</script>

<section class="bg-accent-dark-bg x-wrapper">
	<div class="items-center py-8 lg:grid xl:grid-cols-3 lg:gap-8 xl:gap-24 sm:py-16">
		<div class="mb-8 lg:mb-0">
			<h2 class="mb-4 text-4xl tracking-tight font-extrabold text-accent-primary">
				<T keyName="features_headline" />
			</h2>
			<p class="mb-4 sm:text-xl text-gray-400">
				{@html $t('features_subline')}
			</p>
		</div>
		<div class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
			<div>
				<div
					class="flex justify-center items-center mb-4 w-10 h-10 rounded-lg lg:h-12 lg:w-12 bg-accent-primaryBackground"
				>
					<svg
						class="w-5 h-5 lg:w-6 lg:h-6 text-accent-primary"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
						><path
							fill-rule="evenodd"
							d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
							clip-rule="evenodd"
						/></svg
					>
				</div>
				<h3 class="mb-2 text-xl font-bold text-white">
					<T keyName="features_1_title" />
				</h3>
				<p class="text-gray-400">
					<T keyName="features_1_description" />
				</p>
			</div>
			<div>
				<div
					class="flex justify-center items-center mb-4 w-10 h-10 rounded-lg lg:h-12 lg:w-12 bg-accent-primaryBackground"
				>
					<svg
						class="w-5 h-5 lg:w-6 lg:h-6 text-accent-primary"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						><path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
						/></svg
					>
				</div>
				<h3 class="mb-2 text-xl font-bold text-white">
					<T keyName="features_2_title" />
				</h3>
				<p class="text-gray-400">
					<T keyName="features_2_description" />
				</p>
			</div>
			<div>
				<div
					class="flex justify-center items-center mb-4 w-10 h-10 rounded-lg lg:h-12 lg:w-12 bg-accent-primaryBackground"
				>
					<svg
						class="w-5 h-5 lg:w-6 lg:h-6 text-accent-primary"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						><path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
						/></svg
					>
				</div>
				<h3 class="mb-2 text-xl font-bold text-white">
					<T keyName="features_3_title" />
				</h3>
				<p class="text-gray-400">
					<T keyName="features_3_description" />
				</p>
			</div>
			<div>
				<div
					class="flex justify-center items-center mb-4 w-10 h-10 rounded-lg lg:h-12 lg:w-12 bg-accent-primaryBackground"
				>
					<svg
						class="w-5 h-5 lg:w-6 lg:h-6 text-accent-primary"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
						><path
							fill-rule="evenodd"
							d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
							clip-rule="evenodd"
						/></svg
					>
				</div>
				<h3 class="mb-2 text-xl font-bold text-white">
					<T keyName="features_4_title" />
				</h3>
				<p class="text-gray-400">
					<T keyName="features_4_description" />
				</p>
			</div>
		</div>
	</div>
</section>
