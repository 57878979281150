<script lang="ts">
	import PrimaryButton from './buttons/PrimaryButton.svelte';
	import { T, getTranslate } from '@tolgee/svelte';
	import { tryEmailSubmit, FormPosition } from '$lib/form/submit';
	import enlowLoopMp4 from '$lib/assets/enlow-loop-smallest.mp4';
	import enlowLoopWebm from '$lib/assets/enlow-loop-smallest.webm';
	import Image from '$lib/Image.svelte';
	import { getCDNUrlFromFilename } from '$lib/utils';

	let email = '';
	let allowsAds = true;

	const t = getTranslate();
</script>

<section class="bg-white x-wrapper">
	<div class="py-8 lg:py-16 min-h-[43rem] flex items-center">
		<div class="grid gap-8 items-center mb-8 lg:gap-12 lg:grid-cols-12 w-full">
			<div class="col-span-6 sm:mb-6 lg:text-left lg:mb-0 order-2 lg:order-1 text-center max-w-lg">
				<h1
					class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl xl:text-6xl"
					style="hyphens: manual"
				>
					{@html $t('hero_headline')}
				</h1>
				<p class="mb-6 font-light text-gray-500 xl:mb-8 md:text-lg xl:text-xl">
					{@html $t('hero_subline')}
				</p>
				<form
					method="post"
					on:submit|preventDefault={() => {
						allowsAds = tryEmailSubmit(email, FormPosition.Hero);
					}}
					class=""
				>
					<div
						class="justify-center items-center mx-auto mb-3 space-y-4 sm:flex lg:justify-start sm:space-y-0 sm:space-x-4"
					>
						<div class="relative">
							<label for="emailHero" class="hidden mb-2 text-sm font-medium text-gray-900">
								<T keyName="hero_email_label" />
							</label>
							<input
								bind:value={email}
								class="block p-3 w-full text-sm text-gray-900 bg-white rounded-lg border border-black sm:w-80 xl:w-96 focus:ring-primary-500 focus:border-primary-500"
								placeholder={$t('hero_email_placeholder')}
								type="email"
								id="emailHero"
								required
							/>
						</div>
						<PrimaryButton showArrowRight size="xl">
							<T keyName="hero_button_preorder" />
						</PrimaryButton>
					</div>
					{#if !allowsAds}
						<p class="text-red-500 text-sm">Bitte deaktiviere deinen AdBlocker</p>
					{/if}
					<p class="text-xs text-gray-500 mb-2">
						☝️ WICHTIG: Im Anschluss erhältst du eine E-Mail (Bitte schaue auch unbedingt im SPAM
						nach) mit einem Link, um deine Anmeldung zu bestätigen.
					</p>
					<p class="text-xs text-gray-500">
						Mit der Anmeldung zum Newsletter akzeptierst du die <a href="/privacy" class="underline"
							>Datenschutzbestimmungen</a
						>
					</p>
				</form>
			</div>
			<div class="col-span-6 order-1 lg:order-2">
				<video
					class="rounded-lg w-5/6 mx-auto"
					poster={getCDNUrlFromFilename('enlow-loop-poster.webp', 600)}
					muted
					autoplay
					loop
					playsinline
				>
					<source src={enlowLoopMp4} />
					<source src={enlowLoopWebm} />
					<Image fileName="enlow-loop-poster.webp" />
				</video>
			</div>
		</div>
	</div>
</section>
