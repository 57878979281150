<script lang="ts">
	import Contact from '$lib/Contact.svelte';
	import Content from '$lib/Content.svelte';
	import Features from '$lib/Features.svelte';
	import Hero from '$lib/Hero.svelte';
	import HowItWorks from '$lib/HowItWorks.svelte';
	import Introduction from '$lib/Introduction.svelte';
	import SaveEnergy from '$lib/SaveEnergy.svelte';
	import Marquee from '$lib/Marquee.svelte';
</script>

<svelte:head>
	<title>Enlow</title>
	<meta name="description" content="Einfach Energiesparen" />
</svelte:head>
<div class="flex flex-col gap-10 pt-16">
	<Hero />
	<Features />
	<SaveEnergy />
	<Marquee />
	<Content />
	<HowItWorks />
	<Introduction />
	<Contact headlineKey="contact_headline" />
</div>
