<script lang="ts">
	import { getTranslate } from '@tolgee/svelte';

	const t = getTranslate();
	const toFinal = (text: string) => {
		let finalText = text;
		while (finalText.length < 200) {
			finalText += text;
		}
		return finalText;
	};
</script>

<div class="h-32 w-full bg-[#526357] grid place-items-center">
	<div class="wrapper">
		<div class="marquee text-4xl uppercase text-accent-primary font-extrabold">
			<span class="text-green-primary whitespace-nowrap">
				{toFinal($t('marquee_faq_text'))}
			</span>
			<span class="text-green-primary whitespace-nowrap">
				{toFinal($t('marquee_faq_text'))}
			</span>
		</div>
	</div>
</div>

<style>
	@keyframes marquee {
		0% {
			transform: translate3d(0, 0, 0);
		}
		100% {
			transform: translate3d(-50%, 0, 0);
		}
	}

	.wrapper {
		max-width: 100%;
		overflow: hidden;
	}

	.marquee {
		white-space: nowrap;
		overflow: hidden;
		display: inline-block;
		animation: marquee 40s linear infinite;
	}
</style>
