<script lang="ts">
	import { getTranslate, T } from '@tolgee/svelte';

	const t = getTranslate();
</script>

<section class="bg-accent-primaryBackground x-wrapper">
	<div class="py-8 sm:py-16">
		<div class="mb-8 lg:mb-16">
			<h2 class="mb-4 text-4xl text-center tracking-tight font-extrabold text-accent-primary">
				<T keyName="howitworks_headline" />
			</h2>
		</div>
		<div class="space-y-8 md:grid md:grid-cols-3 md:gap-12 md:space-y-0 text-white">
			<div class="flex flex-col items-center text-center">
				<h3 class="mb-2 text-7xl font-bold">
					<T keyName="howitworks_headline_01" />
				</h3>
				<p>
					{@html $t('howitworks_content_01')}
				</p>
			</div>
			<div class="flex flex-col items-center text-center">
				<h3 class="mb-2 text-7xl font-bold">
					<T keyName="howitworks_headline_02" />
				</h3>
				<p>
					{@html $t('howitworks_content_02')}
				</p>
			</div>
			<div class="flex flex-col items-center text-center">
				<h3 class="mb-2 text-7xl font-bold">
					<T keyName="howitworks_headline_03" />
				</h3>
				<p>
					{@html $t('howitworks_content_03')}
				</p>
			</div>
		</div>
	</div>
</section>
